import { Box, Button } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { addMonths } from 'date-fns'
import { ja } from "date-fns/locale"
import React from 'react'

interface MonthSelectorProps {
  currentMonth: Date;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>
}

const MonthSelector = ({ currentMonth, setCurrentMonth }: MonthSelectorProps) => {

  //年月選択時
  const handleDateChange = (newDate: Date | null) => { //何も入ってないとき対策で型nullを許容
    if (newDate) {  //newDateがnullの場合対応でifで囲む
      setCurrentMonth(newDate)
    }
  }

  //先月選択時
  const handlePreviousMonth = () => {
    const previousMonth = addMonths(currentMonth, -1); //addMonthsで1ヶ月前を取得
    setCurrentMonth(previousMonth);
  }

  //来月選択時
  const handleNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1); //addMonthsで1ヶ月前を取得
    setCurrentMonth(nextMonth);
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      //  dateFormats={{ monthAndYear: "yyyy年 MM月" }} //udemyではこれだがmonthAndYearは存在しない
      dateFormats={{ year: 'yyyy年', month: "M月" }}  //暫定処置
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Button onClick={handlePreviousMonth} color={"error"} variant="contained">
          先月
        </Button>
        <DatePicker
          onChange={handleDateChange}         //値変更時発火。引数あり
          value={currentMonth}                //初期値として現在時刻が入るが、変更されると追従する
          label="年月を選択"
          sx={{ mx: 2, background: "white" }} //X方向の余白の枠内の背景色
          views={["year", "month"]}           //カレンダー表示形式。今回は年・月のみ
          format="yyyy年M月"                    //書式
          slotProps={{
            toolbar: {
              toolbarFormat: "yyyy年M月", //書式
            }
          }}
        />

        <Button onClick={handleNextMonth} color={"primary"} variant="contained">
          次月
        </Button>
      </Box>
    </LocalizationProvider>
  )
}

export default MonthSelector