import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import Home from './pages/Home';
import Report from './pages/Report';
import NoMatch from './pages/NoMatch';
import { theme } from './theme/theme'
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { Transaction } from './types/index';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import { format } from 'date-fns';
import { formatMonth } from './utils/formatting';
import { Schema } from './validations/schema';

function App() {

  //firestoreのエラーかそれ以外なのか判定する「型ガード」。firestoreならtrueを返す
  //firestoreのエラーオブジェクトにはcodeとmessageが含まれるのでそれを感知する
  //err is{ code: string, message: string }の部分が型ガード的書き方。errは{ code: string, message: string }という形式だとtypescriptに返す
  function isFireStoreError(err: unknown): err is { code: string, message: string } {
    //エラーオブジェクトが返されるときの条件。errがobjectで囲まれている。かつ、nullではない。かつ"code"が含まれている
    return typeof err === "object" && err !== null && "code" in err
  }

  const [transactions, setTransactions] = useState<Transaction[]>([]);  //firestoreのデータを収納。型はtypes/index.tsで指定済み

  const [currentMonth, setCurrentMonth] = useState(new Date());//カレンダーで選択されている月の月アタマ1日の値または現在時刻が来る。初期値として現在時刻を入れておく。型はdateだが、new Dateで型推論が効いている。あえて書くならuseState<Date>(new Date())
  //console.log("現在時刻:", currentMonth);

  const [isLoading, setIsLoading] = useState(true); //データ読み込み中にぐるぐるを表示をさせるフラグ


  //useEffectについては、https://reffect.co.jp/react/react-useeffect-understandingがめちゃわかりやすい
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Transactions"));  //firebaseからすべてのデータを取得
        const transactionsData = querySnapshot.docs.map((doc) => {  //querySnapshot内のdocsのデータを取りだして配列データ化。中身をdocと仮称
          return {
            ...doc.data(),  //docの中身を...でぶちまける。
            id: doc.id,     //idはフィールド値ではないので別途取り出す
          } as Transaction  //as Transactionで...doc.dataの中身が型・Transactionで指定のモノだと強制的に教える。これを型アサーションという
        });
        console.log("配列transactionsDataは、", transactionsData);
        setTransactions(transactionsData);  //useState・transactionsにデータをセット
      } catch (err) {
        //取得エラーの際のエラー表示
        if (isFireStoreError(err)) {
          console.error("firestoreのエラーヾ(℃゜)々：", err);
        } else {
          console.error("一般的なエラー(・0・)：", err);
        }
      } finally {
        setIsLoading(false);    //読み込み中のぐるぐる表示を解除
      }
    }
    fetchTransactions();
  }, []);// 最後が空の配列なので、最初にマウントされた時にのみ実行され、再描画時は何もしない

  //currentMonthで指定した月のデータのみtransactionsから抽出
  const monthlyTransactions = transactions.filter((transaction) => {
    return transaction.date.startsWith(formatMonth(currentMonth));//transactionのdate(型:string)が、今月（例: "2024-07"）で始まるデータのみ取得。formatMonthはformatting.tsで指定
  })

  // 伝票を保存
  const handleSaveTransaction = async (transaction: Schema) => {
    try {
      //firestoreにデータを保存。"Transactions"はfirestoreのコレクション（テーブル）名。transactionsは引数
      const docRef = await addDoc(collection(db, "Transactions"), transaction);
      console.log("firestoreに保存したよ。 with ID: ", docRef.id);

      //即座に表示できるようにtransactionを追加
      const newTransaction = {
        id: docRef.id,  //firestore上で作られるid
        ...transaction  //transactionの内容をぶちまける
      } as Transaction; //asで、次行で合体するtransactionsの型に合わせるための型アサーションをする
      setTransactions((prevTransaction) => [
        ...prevTransaction, newTransaction
      ]);   //ぶちまけたtransactionsと新規のデータをくっつける。
      //setTransactions([...transactions, newTransaction])これでも動くが、直前のtransactionsであることを保障するために、正当な書き方は、上記。prevTransactionはsetTransactionsで書き換えられるtransactionsの直前状態である。
    } catch (err) {
      //取得エラーの際のエラー表示
      if (isFireStoreError(err)) {
        console.error("保存：firestoreのエラーヾ(℃゜)々：", err);
      } else {
        console.error("保存：一般的なエラー(・0・)：", err);
      }
    }
  };

  // 伝票を削除
  const handleDeleteTransaction = async (transactionIds: string | readonly string[]) => {  //readonly string[]はレポートの一覧から同時複数削除用
    try {
      const idsTodelete = Array.isArray(transactionIds) ? transactionIds : [transactionIds]; //Array.isArrayは引数が配列の場合のtrueを返してくる。transactionIdsが配列の場合は、そのまま。単一の場合は単一を[]で囲んで中身1つの配列とする

      for (const id of idsTodelete) {   //配列idsTodeleteの中身をidとしてループ処理
        await deleteDoc(doc(db, "Transactions", id)) //firestoreのデータを削除。
      }

      //即座に表示に反影させるためにtransactionから削除
      const filterdTransactions = transactions.filter(
        (transaction) => !idsTodelete.includes(transaction.id)  //削除対象でないデータのみフィルタリングで収集する。includesで配列の中で引数が含まれるものが引き抜かれるので、！でその逆にする
      );
      setTransactions(filterdTransactions); //データ置き替え


      // //即座に表示に反影させるためにtransactionから削除
      // const filterdTransactions = transactions.filter((transaction) => transaction.id !== transactionId); //削除対象でないデータのみフィルタリングで収集する
      // setTransactions(filterdTransactions); //データ置き替え
    } catch (err) {
      //取得エラーの際のエラー表示
      if (isFireStoreError(err)) {
        console.error("保存：firestoreのエラーヾ(℃゜)々：", err);
      } else {
        console.error("保存：一般的なエラー(・0・)：", err);
      }
    }
  }

  //伝票を更新
  const handleUpdateTransaction = async (transaction: Schema, transactionId: string) => {
    try {
      const docRef = doc(db, "Transactions", transactionId);  //　firestore上の更新対象のデータを取得
      await updateDoc(docRef, transaction);                   //firestoreの更新実行
      const updateTransactions = transactions.map((t) => t.id === transactionId ? { ...t, ...transaction } : t) as Transaction[]; //配列要素から取り出したデータのidと選択中のデータのidが一致する場合は、データを更新。一致しない場合はスルー。スプレッド構文を使うことでうまいこと更新してくれる。最後にas Transaction[]でcategory=""対策として型アサーションをする
      setTransactions(updateTransactions); //Transactionsを書き換え
    } catch (err) {
      //取得エラーの際のエラー表示
      if (isFireStoreError(err)) {
        console.error("保存：firestoreのエラーヾ(℃゜)々：", err);
      } else {
        console.error("保存：一般的なエラー(・0・)：", err);
      }
    }
  }

  //console.log("今月は〜:", formatMonth(currentMonth), "データは〜", monthlyTransactions);

  return (
    <ThemeProvider theme={theme}> //テーマを独自付加
      <CssBaseline /> //ブラウザのデフォルトcss解除のために記述
      <Router>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            <Route index element={
              <Home   //ルートデフォルト
                monthlyTransactions={monthlyTransactions}
                setCurrentMonth={setCurrentMonth}
                onSaveTransaction={handleSaveTransaction}
                onDeleteTransaction={handleDeleteTransaction}
                onUpdateTransaction={handleUpdateTransaction}
              />} />
            <Route path="/report" element={
              <Report
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                monthlyTransactions={monthlyTransactions}
                isLoading={isLoading}
                onDeleteTransaction={handleDeleteTransaction}
              />} />
            <Route path="*" element={<NoMatch />} />  //該当ページナシ
          </Route>
        </Routes>
      </Router>
    </ThemeProvider >
  );
}

export default App;
