import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Drawer,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
//アイコン
import NotesIcon from "@mui/icons-material/Notes";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DailySummary from "./DailySummary";
import { Transaction } from "../types";
import { formatCurrency } from "../utils/formatting";
import IconComponents from "./common/IconComponents";
import { theme } from "../theme/theme";
import { Palette } from "@mui/icons-material";

interface TransactionMenuProps {
  dailyTransactions: Transaction[];
  currentDay: string;
  onAddTransactionForm: () => void;
  onSelectTransaction: (transaction: Transaction) => void;
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
}

const TransactionMenu = ({ dailyTransactions, currentDay, onAddTransactionForm, onSelectTransaction, isMobile, open, onClose }: TransactionMenuProps) => {
  const menuDrawerWidth = 320;
  return (
    <Drawer
      sx={{
        width: isMobile ? "auto" : menuDrawerWidth,   //1200以下でいっぱいいっぱい。以上で320
        "& .MuiDrawer-paper": {
          width: isMobile ? "auto" : menuDrawerWidth, //1200以下でいっぱいいっぱい。以上で320
          boxSizing: "border-box",
          p: 2,
          ...(isMobile && {             //1200以下の付加設定
            height: "80vh",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8
          }),
          ...(!isMobile && {             //1200以上の付加設定
            top: 64,
            height: `calc(100% - 64px)`, // AppBarの高さを引いたビューポートの高さ
          })
        },
      }}
      variant={isMobile ? "temporary" : "permanent"} //1200以下で引っ込む。以上で固定
      anchor={isMobile ? "bottom" : "right"}         //1200以下で下から。以上で右側
      open={open}               //このドロワーが開いている条件
      onClose={onClose}         //閉じる動作が行われるとき。前行の{open}がfalseになるので閉じてしまう
      ModalProps={{
        keepMounted: true, // モバイル時のオープンの動作が良くなるらしい
      }}
    >
      <Stack sx={{ height: "100%" }} spacing={2}>
        <Typography fontWeight={"fontWeightBold"}>日時： {currentDay}</Typography>
        <DailySummary
          dailyTransactions={dailyTransactions}
          columns={isMobile ? 3 : 2}  //1200px以下なら3。以上なら2
        />
        {/* 内訳タイトル&内訳追加ボタン */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          {/* 左側のメモアイコンとテキスト */}
          <Box display="flex" alignItems="center">
            <NotesIcon sx={{ mr: 1 }} />
            <Typography variant="body1">内訳</Typography>
          </Box>
          {/* 右側の追加ボタン */}
          <Button
            onClick={onAddTransactionForm}  ////Home.tsxのhandleAddTransactionFormでisEntryDrawerOpenの値反転
            startIcon={<AddCircleIcon />}
            color="primary">
            内訳を追加
          </Button>
        </Box>

        {/*取引一覧*/}
        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List aria-label="取引履歴">
            <Stack spacing={2}>
              {dailyTransactions.map((transaction, index) => (  //Warning: Each child in a list should have a unique "key" prop.とエラーが出るので、indexとkey={index}を自力で加えた
                <ListItem disablePadding key={index}>
                  <Card
                    sx={{
                      width: "100%",
                      backgroundColor: transaction.type === "income"
                        ? (theme) => theme.palette.incomeColor.light
                        : (theme) => theme.palette.expenseColor.light
                    }}
                    onClick={() => onSelectTransaction(transaction)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          wrap="wrap"
                        >
                          <Grid item xs={1}>
                            {/*categoryをキーにして、IconComponentsで定義したアイコンを引き出して表示*/}
                            {IconComponents[transaction.category]}
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              {transaction.category}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body2" gutterBottom>
                              {transaction.content}
                            </Typography>
                          </Grid>
                          <Grid item xs={4.5}>
                            <Typography
                              gutterBottom
                              textAlign={"right"}
                              color="text.secondary"
                              sx={{
                                wordBreak: "break-all",
                              }}
                            >
                              ¥{formatCurrency(transaction.amount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </ListItem>
              ))
              }

            </Stack>
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default TransactionMenu;
