import { dA } from "@fullcalendar/core/internal-common";
import { Balance, Transaction } from "../types";


//収入・支出・収支を計算
export function finaceCalculations(transactions: Transaction[]): Balance {
  //reduceは繰り返し処理。accは累積値。transactionは現在処理中の要素
  return transactions.reduce((acc, transaction) => {
    if (transaction.type === "income") {     //収入の時は
      acc.income += transaction.amount;     //収入の累積値に、要素の金額を加算
    } else {                                //else=支出
      acc.expense += transaction.amount;    //支出の累積値に、要素の金額を加算
    }
    acc.balance = acc.income - acc.expense; //収入から支出を引いた収支

    return acc;
  }, { income: 0, expense: 0, balance: 0 }) //累積値の初期値
}


//1.引数のtransactionsから、日付ごとの収支を計算する関数
/*
このfirestoreのデータ形式
[
{ "type": "income", "category": "給与", "content": "株配当", "amount": 400000, "date": "2024-07-18", "id": "E1ilYRPMMI91WAVWzraN" },
{ "type": "income", "date": "2024-07-17", "content": "宝くじ", "amount": 5000, "category": "副収入", "id": "HX8mTPMizIUqM20GOx5r" },
{ "date": "2024-07-14", "type": "income", "amount": 89000, "content": "取得", "category": "副収入", "id": "IiAIEcy0qlfyMq4h2BIb" }
]
から
以下の形式のオブジェクトを作る
{
"2024-07-14":{income:700,expense:200,balance:500},
"2024-07-18":{income:0,expense:500,balance:-500},
}
 */
export function calculateDailyBalances(transactions: Transaction[]): Record<string, Balance> {
  //Record<string, Balance>で型定義。今回の場合、"2024-07-10":{income: 700, expense: 200, balance: 500}という形にするので、キーは2024-07-10なのでstring。以降のデータはBalanceとする。
  //reduceは繰り返し処理。accは累積値。transactionは現在処理中の要素

  // console.log("firestore素のデータ", transactions);
  return transactions.reduce<Record<string, Balance>>((acc, transaction) => {
    const day = transaction.date;                         //今回処理するtransactionの日付
    if (!acc[day]) {                                      //累積値のキー（日付）に同一dayがない場合は
      acc[day] = { income: 0, expense: 0, balance: 0 }    //値0で新規に作る。あれば既存のモノに
    }
    if (transaction.type === "income") {
      acc[day].income += transaction.amount;              //収入の累積値に、要素の金額を加算
    } else {
      acc[day].expense += transaction.amount;             //支出の累積値に、要素の金額を加算
    }
    acc[day].balance = acc[day].income - acc[day].expense;//収入から支出を引いた収支
    return acc
  }, {})

}


