import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'

import MonthlySummary from '../components/MonthlySummary'
import Calendar from '../components/Calendar'
import TransactionMenu from '../components/TransactionMenu'
import TransactionForm from '../components/TransactionForm'
import { Transaction } from '../types'
import { format } from 'date-fns'
import { Schema } from '../validations/schema'
import { theme } from '../theme/theme'
import { DateClickArg } from '@fullcalendar/interaction'

interface HomeProps {
  monthlyTransactions: Transaction[];
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
  onSaveTransaction: (transaction: Schema) => Promise<void>;
  onDeleteTransaction: (transactionId: string | readonly string[]) => Promise<void>;
  onUpdateTransaction: (transaction: Schema, transactionId: string) => Promise<void>;
}

const Home = ({ monthlyTransactions, setCurrentMonth, onSaveTransaction, onDeleteTransaction, onUpdateTransaction }: HomeProps,) => {
  const today = format(new Date(), "yyyy-MM-dd"); //今日を取得してformatで書式設定&string型変換
  const [currentDay, setCurrentDay] = useState(today);  //選択日。初期値に本日を入れておく
  const [isEntryDrawerOpen, setIsEntryDrawerOpen] = useState(false);  //TransactionFormの開閉状況
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null); //選択された取引を一時収納。初期値はnull。型はnull時にそなえてorでnullも指定

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);  //ドロワー表示のオンオフ記憶。1200px以上では常時表示。この設定は1200px以下のモバイル表示用
  const [isDialogOpen, setIsDialogOpen] = useState(false);  //入力フォームのモバイル時の開閉

  const theme = useTheme();       //テーマを利用可能にする
  const isMobile = useMediaQuery(theme.breakpoints.down('lg')); //lg(=1200)以下になるとtrue
  console.log("モバイル(<1200px):", isMobile);


  //currentMonthで指定した月のデータのみtransactionsから抽出
  const dailyTransactions = monthlyTransactions.filter((transaction) => {
    return transaction.date === currentDay;//transactionのdate(型:string)が、currentDayと一致
  })
  //console.log(currentDay, "のデータは：", dailyTransactions);

  //TransactionFormを閉じる
  const closeForm = () => {
    setSelectedTransaction(null);  //リストからの選択状態を解除。
    if (isMobile) {                  //1200px以下
      setIsDialogOpen(!isDialogOpen)
    } else {                         //1200px以上
      setIsEntryDrawerOpen(!isEntryDrawerOpen);
    };
  }

  //TransactionFormの開閉（内訳を追加）
  const handleAddTransactionForm = () => {
    if (isMobile) {                   //1200px以下
      setIsDialogOpen(true);
    } else {
      if (selectedTransaction) {      //リストからの詳細が選択されている場合
        setSelectedTransaction(null); //選択解除。selectedTransactionの値が変わり、TransactionForm.tsxのuseEffectが発火し、欄内の値がリセットされる。
      } else {
        setIsEntryDrawerOpen(!isEntryDrawerOpen); //単純に開閉
      }
    }
  }

  //取引が選択されたときの処理
  const handleSelectTransaction = (transaction: Transaction) => {
    console.log("選択されたtransactionは", transaction);
    setSelectedTransaction(transaction);  //選択したオブジェクトをstate管理に載せる
    if (isMobile) {                   //1200px以下
      setIsDialogOpen(true);
    } else {
      setIsEntryDrawerOpen(true);//formを開ける
    }
  }

  //カレンダーで日付を選択したとき
  const handleDateClick = (dateInfo: DateClickArg) => {
    setCurrentDay(dateInfo.dateStr);
    setIsMobileDrawerOpen(true);
  }

  //モバイル表示時、ドロワーを閉じる
  const handeCloseMobileDrawer = () => {
    setIsMobileDrawerOpen(false);
  }

  return (
    <Box sx={{ display: "flex" }}> {/*配下のBoxが横並び*/}
      { /*左側コンテンツ*/}
      <Box sx={{ flexGrow: 1 }}>{/*flexGrowで幅一杯まで占有*/}
        <MonthlySummary monthlyTransactions={monthlyTransactions} />
        <Calendar
          monthlyTransactions={monthlyTransactions}
          setCurrentMonth={setCurrentMonth}
          setCurrentDay={setCurrentDay}
          currentDay={currentDay}
          today={today}
          onDateClick={handleDateClick}
        />
      </Box>
      {/*右側コンテンツ*/}
      <Box>
        <TransactionMenu
          dailyTransactions={dailyTransactions}
          currentDay={currentDay}
          onAddTransactionForm={handleAddTransactionForm}
          onSelectTransaction={handleSelectTransaction}
          isMobile={isMobile}
          open={isMobileDrawerOpen}
          onClose={handeCloseMobileDrawer}
        />
        <TransactionForm
          onCloseForm={closeForm}
          isEntryDrawerOpen={isEntryDrawerOpen}
          currentDay={currentDay}
          onSaveTransaction={onSaveTransaction}
          selectedTransaction={selectedTransaction}
          setSelectedTransaction={setSelectedTransaction}
          onDeleteTransaction={onDeleteTransaction}
          onUpdateTransaction={onUpdateTransaction}
          isMobile={isMobile}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </Box>
    </Box>
  )
}

export default Home