import FullCalendar from '@fullcalendar/react'
import React from 'react'
import dayGridPlugin from '@fullcalendar/daygrid'
import jaLocale from '@fullcalendar/core/locales/ja'
import "../calendar.css"
import { DatesSetArg, EventContentArg } from '@fullcalendar/core'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Balance, CalendarContent, Transaction } from '../types'
import { calculateDailyBalances } from '../utils/finaceCalculations'
import { formatCurrency } from '../utils/formatting'
import { theme } from '../theme/theme'
import { useTheme } from '@mui/material'
import { isSameMonth } from 'date-fns'

interface CalendarProps {
  monthlyTransactions: Transaction[];
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
  setCurrentDay: React.Dispatch<React.SetStateAction<string>>;
  currentDay: string;
  today: string;
  onDateClick: (dateInfo: DateClickArg) => void;
}

const Calendar = ({ monthlyTransactions, setCurrentMonth, setCurrentDay, currentDay, today, onDateClick }: CalendarProps) => {

  const theme = useTheme();   //JSX範囲外でthemeを使うために必要

  const events = [
    { start: "2024-07-10", income: 300, expense: 200, balance: 20 },    //startが表示日
    { start: "2024-07-13", income: 50000, expense: 120, balance: 49880 },    //startが表示日
  ]

  const dailyBalances = calculateDailyBalances(monthlyTransactions);  //月間データをぶっ込んでそこから日にちごとの統計を作る
  console.log(dailyBalances);

  //FullCalendar用のeventを生成
  /*
  上段の形式のオブジェクトををフルカレンダーで利用できる下段に変換しする。
  {
  "2024-07-14":{income:700,expense:200,balance:500},
  "2024-07-18":{income:0,expense:500,balance:-500},
  }
  
    ↓変換
  
  [
  {start:"2024-07-14",income:700,expense:200,balance:500},
  {start:"2024-07-18",income:0,expense:500,balance:-500}
  ]
  */
  const createCalendarEvents = (dailyBalances: Record<string, Balance>): CalendarContent[] => {
    //Object.keys(dailyBalances)でdailyBalancesのキー(例:2024-07-14)のみ取得して配列を作る。
    //それをmapでひとつづ取り出してdateとし、加工処理に回す。
    return Object.keys(dailyBalances).map((date) => {
      //前段階のオブジェクトdailyBalancesからキーを使って呼び出し、個別要素を分割代入
      const { income, expense, balance } = dailyBalances[date];
      return {
        start: date,
        income: formatCurrency(income),
        expense: formatCurrency(expense),
        balance: formatCurrency(balance)
      }
    })
  }

  const calendarEvents = createCalendarEvents(dailyBalances);

  //選択した日をカレンダーで表示可能な形式にオブジェクト化
  //　例：{ start: "2024-07-18", display: "background", backgroundColor: "red" }
  const backgroundEvent = {
    start: currentDay,
    display: "background",
    backgroundColor: theme.palette.incomeColor.light  //ここでのthemeはJSX外でテーマを使用するために作った「const theme = useTheme()」定数。
  }


  //カレンダーイベントの見た目
  const renderEventContent = (evetInfo: EventContentArg) => {   //EventContentArgはeventContentの型
    //ここからjsxで記述
    return (
      <div>
        <div className='money' id="event-income">
          {evetInfo.event.extendedProps.income}
        </div>
        <div className='money' id="event-expense">
          {evetInfo.event.extendedProps.expense}
        </div>
        <div className='money' id="event-balance">
          {evetInfo.event.extendedProps.balance}
        </div>
      </div>
    )
  }

  const handleDateSet = (datesetInfo: DatesSetArg) => {
    const currentMonth = datesetInfo.view.currentStart;  //datesetInfo.view.currentStartで表示された月アタマの値(例：Fri Nov 01 2024 00:00:00 GMT +0900(日本標準時))を取得できる。
    setCurrentMonth(currentMonth); //App.tsx内のcurrentMonthの値を月アタマの値にする
    console.log("選択月は:", datesetInfo);
    const todayDate = new Date();
    //月変更ボタンを押した時に、無条件に日別詳細が今日に切り替わらないようにする。
    if (isSameMonth(todayDate, currentMonth)) { //isSameMonthはdate-fnsの関数。//現在表示中の月が実際の月と同一であれば実行
      setCurrentDay(today);
    }
  }



  return (
    <FullCalendar
      locale={jaLocale}
      plugins={[dayGridPlugin, interactionPlugin]}           //月カレンダーとdateClick
      initialView='dayGridMonth'          //月間ビュー
      events={[...calendarEvents, backgroundEvent]}             //各日の集計金額と、選択日の背景色を表示。calendarEventsを一旦スプレッド構文でバラバラにして、 選択日イベントを加え、再配列化する
      eventContent={renderEventContent}   //独自要素の表示設定
      datesSet={handleDateSet}            //月変更のボタンまたは今日ボタンが押された
      dateClick={onDateClick}             //日が押された。
    />
  )
}

export default Calendar