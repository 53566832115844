import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { AspectRatio } from '@mui/icons-material';
import { interactionSettingsStore } from '@fullcalendar/core/internal';
import { Transaction } from '../types';
import { calculateDailyBalances } from '../utils/finaceCalculations';
import { da, is } from 'date-fns/locale';
import { useTheme, CircularProgress, Box, Typography } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  monthlyTransactions: Transaction[];
  isLoading: boolean;
}

const BarChart = ({ monthlyTransactions, isLoading }: BarChartProps) => {
  const theme = useTheme();   //テーマを読み込み
  const options = {
    maintainAspectRatio: false,   //表示のタテヨコ比率。ボックスにピッタリになるようタテヨコ自由可変設定
    responsive: true,
    plugins: {
      // legend: {  項目の表示位置。デフォルト上なのでコメントアウトしておく
      //   position: "top" as const,
      // },
      title: {
        display: true,
        text: "日別収支",
      }
    },
  };

  const dailyBalances = calculateDailyBalances(monthlyTransactions);
  console.log("monthlyTransactionsが", monthlyTransactions, "、で、dailyBalancesが、", dailyBalances);

  const dateLabels = Object.keys(dailyBalances).sort();  //オブジェクトのキーのみを首都きして配列化する。昇順にしたいので.sort()を付ける。これだけで表示用日付ラベルの完成
  const expenseData = dateLabels.map((day) => dailyBalances[day].expense);//日ごとの支出を配列化。日付配列dateLabels内の日付をmapで取り出しながら、dailyBalancesから引っこ抜いて配列化。
  const incomeData = dateLabels.map((day) => dailyBalances[day].income);//日ごとの収入を配列化。日付配列dateLabels内の日付をmapで取り出しながら、dailyBalancesから引っこ抜いて配列化。

  //console.log("日付ラベルは", dateLabels, "支出配列は", expenseData, "、収入配列は", incomeData);

  const data = {
    labels: dateLabels,   //下に表示される日付。["2024-07-10","2024-01-11","2024-01-12"]な形式
    datasets: [
      {
        label: "支出",
        data: expenseData,  //[600, 650, 700, 650]な形式
        backgroundColor: theme.palette.expenseColor.light,
      },
      {
        label: "収入",
        data: incomeData,   //[650, 200, 500, 550,]な形式
        backgroundColor: theme.palette.incomeColor.light,
      },

    ]
  }



  return (
    <Box sx={{
      flexGrow: 1,                      //アイテムが１つなので、flexGrow: 1で最大限大きくなる
      display: "flex",                  //flexコンテナになる
      alignItems: "center",             //垂直方向中央
      justifyContent: "center"          //水辺方向中央
    }}>
      {
        isLoading ? (                             //データが読み込まれていない状態
          <CircularProgress />                    //読み込み中ぐるぐる表示
        ) : monthlyTransactions.length > 0 ? (    //読込後、その月に月間データのある場合
          <Bar options={options} data={data} />   //グラフ表示
        ) : (                                     //ない場合
          <Typography>データがありません</Typography>
        )}
    </Box >
  );
};

export default BarChart