import { Grid, Paper } from '@mui/material'
import React from 'react'
import MonthSelector from '../components/MonthSelector'
import CategoryChart from '../components/CategoryChart'
import TransactionTable from '../components/TransactionTable'
import BarChart from '../components/BarChart'
import { Transaction } from '../types'

interface ReportProps {
  currentMonth: Date;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
  monthlyTransactions: Transaction[];
  isLoading: boolean;
  onDeleteTransaction: (transactionId: string | readonly string[]) => Promise<void>;
}


export const Report = ({ currentMonth, setCurrentMonth, monthlyTransactions, isLoading, onDeleteTransaction }: ReportProps) => {
  const commonPaperStyle = {
    height: "400px", //0ピクセル以上でauto(内容次第で収縮)。900ピクセル以上で400px
    display: "flex",  //子要素がフレックスアイテムになる。中のコンテンツをあれこれflex系設定するために必要
    flexDirection: "column",               //中の要素を縦並びにする
    p: 2,                    //padding
  }

  return (
    <Grid container spacing={2}>  {/*container=最大枠*/}
      <Grid item xs={12} >
        {/*日付選択エリア*/}
        <MonthSelector
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper sx={commonPaperStyle}> {/*Paper=紙っぽいかげつき表示*/}
          <CategoryChart
            monthlyTransactions={monthlyTransactions}
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper sx={commonPaperStyle}> {/*Paper=紙っぽいかげつき表示*/}
          <BarChart
            monthlyTransactions={monthlyTransactions}
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TransactionTable
          monthlyTransactions={monthlyTransactions}
          onDeleteTransaction={onDeleteTransaction}
        />
      </Grid>
    </Grid>
  )
}

export default Report