import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { theme } from '../theme/theme';
import { Transaction } from '../types';
import { finaceCalculations } from '../utils/finaceCalculations';
import { formatCurrency } from '../utils/formatting';

interface MonthlySummaryProps {
  monthlyTransactions: Transaction[],
}

const MonthlySummary = ({ monthlyTransactions }: MonthlySummaryProps) => {
  const { income, expense, balance } = finaceCalculations(monthlyTransactions);  //分割代入

  return (
    /*大枠*/
    <Grid container spacing={{ xs: 1, sm: 2 }} mb={2}>
      {/*個別要素・収入*/}
      <Grid item xs={4} display={"flex"} flexDirection={"column"}>{/*display=flexで各要素高さ揃え*/}
        <Card //中身
          sx={{
            bgcolor: (theme) => theme.palette.incomeColor.main,
            color: "white",
            borderRadius: "10px",
            flexGrow: 1,           //タテサイズ目一杯
          }}>
          <CardContent sx={{ padding: { xs: 1, sm: 2 } }}>
            <Stack direction={"row"}> {/*direction:rowでStack内は横並び*/}
              <ArrowDownwardIcon sx={{ fontSize: "2rem" }} />
              <Typography>支出</Typography>
            </Stack>
            <Typography
              textAlign={"right"}
              variant='h5'
              fontWeight={"fontWeightBold"}     //themeで設定済み
              sx={{
                wordBreak: "break-word",
                fontSize: { xs: ".8rem", sm: "1rem", md: "1.2rem" },
              }}  //長い時は、文字折り返し
            > ¥{formatCurrency(income)}</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/*個別要素・支出*/}
      <Grid item xs={4} display={"flex"} flexDirection={"column"}>
        <Card //中身
          sx={{
            bgcolor: (theme) => theme.palette.expenseColor.main,
            color: "white",
            borderRadius: "10px",
            flexGrow: 1,           //タテサイズ目一杯
          }}>
          <CardContent sx={{ padding: { xs: 1, sm: 2 } }}>
            <Stack direction={"row"}> {/*direction:rowでStack内は横並び*/}
              <ArrowUpwardIcon sx={{ fontSize: "2rem" }} />
              <Typography>収入</Typography>
            </Stack>
            <Typography
              textAlign={"right"}
              variant='h5'
              fontWeight={"fontWeightBold"}     //themeで設定済み
              sx={{
                wordBreak: "break-word",
                fontSize: { xs: ".8rem", sm: "1rem", md: "1.2rem" },
              }}  //長い時は、文字折り返し
            > ¥{formatCurrency(expense)}</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/*個別要素・残高*/}
      <Grid item xs={4} display={"flex"} flexDirection={"column"}>
        <Card //中身
          sx={{
            bgcolor: (theme) => theme.palette.balanceColor.main,
            color: "white",
            borderRadius: "10px",
            flexGrow: 1,           //タテサイズ目一杯
          }}>
          <CardContent sx={{ padding: { xs: 1, sm: 2 } }}>
            <Stack direction={"row"}> {/*direction:rowでStack内は横並び*/}
              <AccountBalanceIcon sx={{ fontSize: "2rem" }} />
              <Typography>残高</Typography>
            </Stack>
            <Typography
              textAlign={"right"}
              variant='h5'
              fontWeight={"fontWeightBold"}     //themeで設定済み
              sx={{
                wordBreak: "break-word",
                fontSize: { xs: ".8rem", sm: "1rem", md: "1.2rem" },
              }}  //長い時は、文字折り返し
            > ¥{formatCurrency(balance)}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  )
}

export default MonthlySummary