// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// 自分のfirebaseと接続する設定。firebaseのHPからコピペで持ってきた。
const firebaseConfig = {
  apiKey: "AIzaSyCpSrZqOckJ82NPdsOkY9iqXDnZtbljaEQ",
  authDomain: "householdtypescript-b8e25.firebaseapp.com",
  projectId: "householdtypescript-b8e25",
  storageBucket: "householdtypescript-b8e25.appspot.com",
  messagingSenderId: "989624091545",
  appId: "1:989624091545:web:52497ec2df2f7104ea4908"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);  //firestoreの初期化

export { db };