import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close"; // 閉じるボタン用のアイコン
import FastfoodIcon from "@mui/icons-material/Fastfood"; //食事アイコン
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ExpenseCategory, IncomeCategory, Transaction } from "../types";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddHomeIcon from "@mui/icons-material/AddHome";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import TrainIcon from "@mui/icons-material/Train";
import WorkIcon from "@mui/icons-material/Work";
import SavingsIcon from "@mui/icons-material/Savings";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { cA } from "@fullcalendar/core/internal-common";
import { Category } from "@mui/icons-material";

import { zodResolver } from "@hookform/resolvers/zod";
import { Schema, transactionSchema } from "../validations/schema";
import FormControlContext from "@mui/material/FormControl/FormControlContext";

interface TransactionFormrops {
  onCloseForm: () => void;
  isEntryDrawerOpen: boolean;
  currentDay: string;
  onSaveTransaction: (transaction: Schema) => Promise<void>;
  selectedTransaction: Transaction | null;
  setSelectedTransaction: React.Dispatch<React.SetStateAction<Transaction | null>>;
  onDeleteTransaction: (transactionId: string | readonly string[]) => Promise<void>;
  onUpdateTransaction: (transaction: Schema, transactionId: string) => Promise<void>;
  isMobile: boolean;
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

//入力フォームの入出の型定義
type IncomeExpense = "income" | "expense";

interface CategoryItem {
  label: IncomeCategory | ExpenseCategory;
  icon: JSX.Element
}

const TransactionForm = ({ onCloseForm, isEntryDrawerOpen, currentDay, onSaveTransaction, selectedTransaction, setSelectedTransaction, onDeleteTransaction, onUpdateTransaction, isMobile, isDialogOpen, setIsDialogOpen }: TransactionFormrops) => {
  const formWidth = 320;

  // 支出用カテゴリ
  const expenseCategories: CategoryItem[] = [
    { label: "食費", icon: <FastfoodIcon fontSize="small" /> },
    { label: "日用品", icon: <AlarmIcon fontSize="small" /> },
    { label: "住居費", icon: <AddHomeIcon fontSize="small" /> },
    { label: "交際費", icon: <Diversity3Icon fontSize="small" /> },
    { label: "娯楽", icon: <SportsTennisIcon fontSize="small" /> },
    { label: "交通費", icon: <TrainIcon fontSize="small" /> },
  ];
  // 収入用カテゴリ
  const incomeCategories: CategoryItem[] = [
    { label: "給与", icon: <WorkIcon fontSize="small" /> },
    { label: "副収入", icon: <AddBusinessIcon fontSize="small" /> },
    { label: "お小遣い", icon: <SavingsIcon fontSize="small" /> },
  ];

  //カテゴリの一覧に出すリスト
  const [categories, setCategories] = useState(expenseCategories);

  //入力フォームのためにuseFormを設定
  const {
    control,
    setValue, //値をセット
    watch,    //変化を監視
    formState: { errors },  //エラーを取得
    handleSubmit,           //バリデーション通過したら実行
    reset,                  //データ送信後に欄内の値をリセット
  } = useForm<Schema>({ //useFormはreact-hook-formの要素
    defaultValues: {
      type: "expense",
      date: currentDay,
      amount: 0,
      category: "",
      content: "",
    },
    resolver: zodResolver(transactionSchema), //バリデーションの設定。引数に作成したtransactionSchemaを指定。
  })


  //収入・支出の値をセット
  const incomeExpenseToggle = (type: IncomeExpense) => {
    setValue("type", type);
    setValue("category", ""); //収支タイプを切り替えたタイミングで既に入っているcategoryを一旦空にする
  }

  //watchでtypeの値変更を監視
  const currentType = watch("type");

  //useEffectにより、初回描画時と、[currentDay]が変更されたときに、dateにcurrentDayの値を入れる
  useEffect(() => {
    setValue("date", currentDay);
  }, [currentDay]);

  //useEffectにより、初回描画時と、[currentType]が変更されたときに、カテゴリで出す一覧を切り替える。
  useEffect(() => {
    const newCategories = currentType === "expense" ? expenseCategories : incomeCategories;
    setCategories(newCategories);
  }, [currentType]);

  //カテゴリの選択肢が更新されたか確認。フォームで　収入/支出を切り替えると、上のuseEffectでカテゴリの選択肢が切り替わるが、切り替えタイミングが合わず「収入なのに食費が選択肢になってる」などの警告が出てしまう。その対策
  useEffect(() => {
    if (selectedTransaction) {    //selectedTransactionに値がある場合のみ
      const categoryExists = categories.some(
        (category) => category.label === selectedTransaction.category  //categoryのlabelの中に選択データのカテゴリの値を含んでいたら、trueを返す。some関数は配列内の少なくとも1つの要素が条件を満たしているとtrueを返す。
      );
      setValue("category", categoryExists ? selectedTransaction.category : ""); //trueなら更新。falseなら空文字
    }

  }, [selectedTransaction, categories]) //どちらかに値の変化があれば発火。


  //useEffectにより、selectedTransactionの変更。つまりリストから取引オブジェクトが選択されたときにフォームの値を変更
  useEffect(() => {
    if (selectedTransaction) {    //selectedTransactionに値がある場合のみ
      setValue("type", selectedTransaction.type);
      setValue("date", selectedTransaction.date);
      setValue("amount", selectedTransaction.amount);
      setValue("content", selectedTransaction.content);
      //     console.log("idをお知らせします", selectedTransaction.id)
    } else {    //home.tsxのhandleAddTransactionFormで発生させている。選択状態を解除して内訳追加が可能にする
      reset({               //入力枠内の値をリセット。（）内が空だとdefaultValuesにリセット。今回はdateを直前に入力した値にするために、再定義している。
        type: "expense",
        date: currentDay,
        amount: 0,
        category: "",
        content: "",
      }
      );
    }
  }, [selectedTransaction])




  //送信処理
  const onSubmit: SubmitHandler<Schema> = (data) => {    //型Schemaはschema.tsで作成したもの。const onSubmit = (data: Schema) => { と書くのが基本だが、react-hook-homeでこの書き方が推奨されている。結果は同じ
    if (selectedTransaction) {
      onUpdateTransaction(data, selectedTransaction.id)
        .then(() => {  //firestoreへのデータ更新と、メモリのtransactionsデータの更新
          setSelectedTransaction(null); //thenで更新が終わってから選択データのnull化を実行
          if (isMobile) {
            setIsDialogOpen(false);
          }
          console.log("更新しました");
        }).catch((error) => { console.error(error) })
    } else {
      onSaveTransaction(data)
        .then(() => {  //firestoreへの保存と、メモリのtransactionsデータへの追加
          console.log("新規保存しました");
        }).catch((error) => { console.error(error) })
    }
    reset({               //入力枠内の値をリセット。（）内が空だとdefaultValuesにリセット。今回はdateを直前に入力した値にするために、再定義している。
      type: "expense",
      date: currentDay,
      amount: 0,
      category: "",
      content: "",
    }
    );
  }

  //削除
  const handleDelete = () => {
    if (selectedTransaction) {  //リストから詳細が選択されているとき。
      onDeleteTransaction(selectedTransaction.id);  //削除実行
      setSelectedTransaction(null); //詳細選択状態の解除
      if (isMobile) {
        setIsDialogOpen(false);
      }
    }
  }


  //表示のコア部分
  const formContent = (
    <div>
      {/* 入力エリアヘッダー */}
      <Box display={"flex"} justifyContent={"space-between"} mb={2}>
        <Typography variant="h6">入力</Typography>
        {/* 閉じるボタン */}
        <IconButton
          onClick={onCloseForm} //Home.tsxのcloseFormでisEntryDrawerOpenの値反転
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* フォーム要素 */}
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>  {/*バリデーションチェックを通った場合のみonSubmit関数が実行される。*/}
        <Stack spacing={2}>
          {/* 収支切り替えボタン */}
          <Controller     //Controllerはreact-hook-formの要素
            name="type"
            control={control}
            render={({ field }) => ( //描画する要素
              //      console.log(field);
              <ButtonGroup fullWidth>
                <Button
                  variant={
                    field.value === "expense" ? "contained" : "outlined"
                  }
                  color="error"
                  onClick={() => incomeExpenseToggle("expense")}
                >
                  支出
                </Button>
                <Button
                  variant={
                    field.value === "income" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => incomeExpenseToggle("income")}
                >収入
                </Button>
              </ButtonGroup>
            )
            }
          />

          {/* 日付 */}
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="日付"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.date} //バリデーションに引っかかったとき、枠をerror色にする。!!2つで、errosオブジェクトにdateプロパティが含まれているとtrueになる。!１つだと論理否定演算子 !ということになって、答えが反対になるので、もう一個!つけて再逆転させる
                helperText={errors.date?.message}  //エラーメッセージ。?でオプショナルチェーン設定。？がないとdateがない場合（正常入力）にエラーになる。

              />
            )}
          />

          {/* カテゴリ */}
          <Controller
            name="category"
            control={control}
            render={({ field }) => (

              <FormControl
                fullWidth
                error={!!errors.category}//バリデーションに引っかかったとき、枠をerror色にする。!!2つで、errosオブジェクトにdateプロパティが含まれているとtrueになる。!１つだと論理否定演算子 !ということになって、答えが反対になるので、もう一個!つけて再逆転させる
              >
                <InputLabel id="category-select-label">カテゴリ</InputLabel>
                <Select
                  {...field}
                  labelId="category-select-label"
                  id="category-select"
                  label="カテゴリ"
                >
                  {categories.map((category, index) => (
                    <MenuItem value={category.label} key={index}>
                      <ListItemIcon>{category.icon}</ListItemIcon>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.category?.message}   {/*エラーメッセージ。?でオプショナルチェーン設定。？がないとcategoryがない場合（正常入力）にエラーになる。*/}
                </FormHelperText>
              </FormControl>
            )}
          />

          {/* 金額 */}
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value === 0 ? "" : field.value}  //0の時は表示しない
                onChange={(e) => {                            //入力値が変わると発火
                  const newValue = parseInt(e.target.value, 10) || 0; //string型の入力値を10進数の数値に変換。ただし入力値なし("")の場合は0を入れてエラー回避
                  field.onChange(newValue); //値が変化したら。fieldの値を変更（こちらのonChangeはreact-hook-formのメソッド）
                }}
                label="金額"
                type="number"
                error={!!errors.amount} //バリデーションに引っかかったとき、枠をerror色にする。!!2つで、errosオブジェクトにdateプロパティが含まれているとtrueになる。!１つだと論理否定演算子 !ということになって、答えが反対になるので、もう一個!つけて再逆転させる
                helperText={errors.amount?.message}  //エラーメッセージ。?でオプショナルチェーン設定。？がないとamountがない場合（正常入力）にエラーになる。
              />
            )}
          />

          {/* 内容 */}
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="内容"
                type="text"
                error={!!errors.content} //バリデーションに引っかかったとき、枠をerror色にする。!!2つで、errosオブジェクトにdateプロパティが含まれているとtrueになる。!１つだと論理否定演算子 !ということになって、答えが反対になるので、もう一個!つけて再逆転させる
                helperText={errors.content?.message}  //エラーメッセージ。?でオプショナルチェーン設定。？がないとcontentがない場合（正常入力）にエラーになる。
              />
            )}
          />
          {/* 保存ボタン */}
          <Button
            type="submit"
            variant="contained"
            color={currentType === "income" ? "primary" : "error"}
            fullWidth
          >
            {selectedTransaction ? "更新" : "保存"}
          </Button>

          {/* 削除ボタン */}
          {selectedTransaction && //selectedTransactionに値があるときのみ表示
            <Button
              variant="outlined"
              color={"secondary"}
              fullWidth
              onClick={handleDelete}
            >
              削除
            </Button>
          }
        </Stack>
      </Box>
    </div>
  )

  return (
    <div>
      {isMobile ? (
        //1200px以下
        <Dialog
          open={isDialogOpen}       //isDialogOpenがtrueの時に開いた状態
          onClose={onCloseForm}     //閉じる行為をするとonCloseFormが発火
          fullWidth maxWidth={"sm"}>  {/*表示幅は広くするが、最大幅はsm(600px)*/}
          <DialogContent>
            {formContent}
          </DialogContent>
        </Dialog>
      ) : (
        //1200px以上
        <Box
          sx={{
            position: "fixed",
            top: 64,
            right: isEntryDrawerOpen ? formWidth : "-2%", // falseの時は閉。0%や0pxだと影が残ってしまうのでマイナス値
            width: formWidth,
            height: "100%",
            bgcolor: "background.paper",
            zIndex: (theme) => theme.zIndex.drawer - 1,
            //アニメーションの設定。
            transition: (theme) =>
              theme.transitions.create("right", { //rightに対して効果を作成
                easing: theme.transitions.easing.sharp, //速度はシャープに
                duration: theme.transitions.duration.enteringScreen,  //継続時間は標準的
              }),
            p: 2, // 内部の余白
            boxSizing: "border-box", // ボーダーとパディングをwidthに含める
            boxShadow: "0px 0px 15px -5px #777777",
          }}
        >
          {formContent}
        </Box>
      )
      }

    </div>
  );
};
export default TransactionForm;
