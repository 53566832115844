import React, { useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import { TextFields } from '@mui/icons-material';
import { ExpenseCategory, IncomeCategory, Transaction, TransactionType } from '../types';

ChartJS.register(ArcElement, Tooltip, Legend);

interface CategoryChartProps {
  monthlyTransactions: Transaction[];
  isLoading: boolean;
}


const CategoryChart = ({ monthlyTransactions, isLoading }: CategoryChartProps) => {

  const theme = useTheme();   //themeを利用可能にする

  const [selectedType, setSelectedType] = useState<TransactionType>("expense"); //表示セレクト。型はtypes/index.tsで設定済み


  /*
  [
    { "amount": 40000, "content": "株配当", "category": "給与", "type": "income", "date": "2024-07-18", "id": "E1ilYRPMMI91WAVWzraN" },
    { "type": "income", "category": "副収入", "content": "宝くじ", "amount": 5000, "date": "2024-07-17", "id": "HX8mTPMizIUqM20GOx5r" },
    { "amount": 89000, "type": "income", "category": "副収入", "content": "取得", "date": "2024-07-14", "id": "IiAIEcy0qlfyMq4h2BIb" },
    { "category": "お小遣い", "date": "2024-07-22", "type": "income", "amount": 1000, "content": "母から", "id": "QGbo8c0KLdpb5DwR1AMq" }
  ]
  を
  { "給与": 400000, "副収入": 94000, "お小遣い": 1000 }
  に変換
  */
  const categorySums = monthlyTransactions
    .filter(
      (transaction) => transaction.type === selectedType
    ).reduce<Record<IncomeCategory | ExpenseCategory, number>>((acc, transaction) => { //accは累積値。transactionは処理中のオブジェクト
      if (!acc[transaction.category]) {     //初出のカテゴリの場合
        acc[transaction.category] = 0       //0円で作る
      }
      acc[transaction.category] += transaction.amount;  //ループしながらカテゴリごとの金額を集計
      return acc;
    }, {} as Record<IncomeCategory | ExpenseCategory, number>   //{}がaccの初期値になるが(今回はナシ)、事前に型を付けておく必要がある。よってreduceと同じ型を指定しておく
    );
  console.log("categorySums:", categorySums);

  const categoryLabels = Object.keys(categorySums) as (IncomeCategory | ExpenseCategory)[]; //categorySumsのキーのみ配列で取り出す。asで型アサーションしないとstring型になってしまうので注意
  console.log("categoryLabels:", categoryLabels);

  const categoryValues = Object.values(categorySums); //categorySumsの値のみ配列で取り出す。
  console.log("categoryValues:", categoryValues);


  //収入・支出どちらが選択されているか
  const handleChange = (e: SelectChangeEvent<TransactionType>) => {
    setSelectedType(e.target.value as TransactionType); //e.target.valueは型stringだが、setSelectedTypeｇは型TransactionTypeなのでエラーがでる。なので、型アサーションでas　TransactionTypeしておく
  }

  //円グラフのスタイル設定
  const options = {
    maintainAspectRatio: false,   //表示のタテヨコ比率。ボックスにピッタリになるようタテヨコ自由可変設定
    responsive: true,
  };

  //収入用カテゴリカラー
  const incomeCategoryColor: Record<IncomeCategory, string> = {
    給与: theme.palette.incomeCategoryColor.給与,
    副収入: theme.palette.incomeCategoryColor.副収入,
    お小遣い: theme.palette.incomeCategoryColor.お小遣い,
  };

  //支出用カテゴリカラー
  const expenseCategoryColor: Record<ExpenseCategory, string> = {
    食費: theme.palette.expenseCategoryColor.食費,
    日用品: theme.palette.expenseCategoryColor.日用品,
    住居費: theme.palette.expenseCategoryColor.住居費,
    交際費: theme.palette.expenseCategoryColor.交際費,
    交通費: theme.palette.expenseCategoryColor.交通費,
    娯楽: theme.palette.expenseCategoryColor.娯楽,
  };

  //収入・支出で呼び出す色を切り替え
  const getCategoryColor = (
    category: IncomeCategory | ExpenseCategory
  ): string => {
    if (selectedType === "income") {
      return incomeCategoryColor[category as IncomeCategory];//ExpenseCategoryは含まれないので型アサーション
    } else {
      return expenseCategoryColor[category as ExpenseCategory];//IncomeCategoryは含まれないので型アサーション
    }
  };


  const data: ChartData<"pie"> = {
    labels: categoryLabels, //ラベル表示。例：['Red', 'Blue', 'Yellow']
    datasets: [
      {
        data: categoryValues, //グラフデータ。例：[12, 19, 3, 5, 2, 3]
        //グラフ各項目背景色。例：'[rgba(255,99,132,0.2)','rgba(54,162,235,0.2)]
        backgroundColor: categoryLabels.map((category) => {
          return getCategoryColor(category);
        }),
        //グラフ各項目ボーダー色。例：'[rgba(255,99,132,0.2)','rgba(54,162,235,0.2)]
        borderColor: categoryLabels.map((category) => {
          return getCategoryColor(category);
        }),
        borderWidth: 1,
      }
    ]
  }



  return (
    <>

      <FormControl fullWidth>
        <InputLabel id="type-select-label">収支の種類</InputLabel>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={selectedType}
          label="収支の種類"
          onChange={handleChange}
        >
          <MenuItem value="income">収入</MenuItem>
          <MenuItem value="expense">支出</MenuItem>
        </Select>
      </FormControl>




      {/* <TextField
        select                    //セレクト
        label="収支の種類"          //ラベル
        value={selectedType}      //表示される値
        onChange={handleChange}   //値変更したら
        fullWidth                 //Box内で最大幅
      >
        <MenuItem value={"income"}>収入</MenuItem>
        <MenuItem value={"expense"}>支出</MenuItem>
      </TextField> */}
      <Box sx={{
        flexGrow: 1,                      //アイテムが１つなので、flexGrow: 1で最大限大きくなる
        display: "flex",                  //flexコンテナになる
        alignItems: "center",             //垂直方向中央
        justifyContent: "center"          //水辺方向中央
      }}>


        {
          isLoading ? (                             //データが読み込まれていない状態
            <CircularProgress />                    //読み込み中ぐるぐる表示
          ) : monthlyTransactions.length > 0 ? (    //読込後、その月に月間データのある場合
            <Pie data={data} options={options} />   //グラフ表示
          ) : (                                     //ない場合
            <Typography>データがありません</Typography>
          )}


      </Box>
    </>
  );
}

export default CategoryChart